import React, { useState } from 'react';
import './styles/Contact.css';

const contacts = [
  {
    title: 'Mərkəz Ofis',

    phone1: '+994 50 299 44 05',
    email: 'info@hunmotors.com',
  },
  {
    title: 'Satış meneceri',

    phone1: '+994 55 868 53 63',
    email: 'sales@hunmotors.com',
  },
  // Add more contacts here if needed
];

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('http://localhost:5000/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert('Message sent successfully!');
      setFormData({ name: '', email: '', phone: '', subject: '', message: '' });
    } else {
      alert('Failed to send message');
    }
  };

  return (
    <div className="contact-page">
      {/* Main Contacts Section */}
      <div className="contact-grid">
        {contacts.map((contact, index) => (
          <div className="contact-card" key={index}>
            <h3>{contact.title}</h3>

            <p><strong>Nömrə:</strong> {contact.phone1}</p>
            {contact.phone2 && <p><strong>Nömrə:</strong> {contact.phone2}</p>}
            <p><strong>E-poçt:</strong> <a href={`mailto:${contact.email}`}>{contact.email}</a></p>
          </div>
        ))}
      </div>

      {/* Contact Form with Map in the Background */}
      <div className="form-map-wrapper">
        <div className="map-background">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=..."
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
        <div className="contact-form-overlay">
          <form className="contact-form" onSubmit={handleSubmit}>
            <h2>Biza sualınız?</h2>
            <input
              type="text"
              name="name"
              placeholder="Ad Soyad"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="E-poçt"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Nömrə"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="subject"
              placeholder="Başlıq"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Mesajınız"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <div className="recaptcha">
              <input type="checkbox" /> I’m not a robot
            </div>
            <button type="submit">Göndər</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
