import React, { useEffect, useRef, useState } from "react";
import "./styles/SliderComponent.css";

const StatsSection = () => {
  const sectionRef = useRef(null);
  const [startCount, setStartCount] = useState(false);

  useEffect(() => {
    const section = sectionRef.current;
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setStartCount(true);
            section.classList.add("animate");
          } else {
            section.classList.remove("animate");
          }
        });
      },
      { threshold: 0.5 }
    );

    if (section) observer.observe(section);

    return () => {
      if (section) observer.unobserve(section);
    };
  }, []);

  // Counter effect for the numbers
  const CountUp = ({ end }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      if (startCount) {
        let start = 0;
        const duration = 2000; // 2 seconds for the counter animation
        const stepTime = Math.abs(Math.floor(duration / end));

        const timer = setInterval(() => {
          start += 1;
          setCount(start);
          if (start >= end) clearInterval(timer);
        }, stepTime);
      }
    }, [end, startCount]);

    return <span>{count}</span>;
  };

  return (
    <div ref={sectionRef} className="stats-section">
      <div className="image-content">
        <img
          src="https://www.autotrainingcentre.com/wp-content/uploads/2015/09/Top-5-Most-Common-Repairs-Youll-Encounter-in-an-Auto-Repair-Career.jpg"
          alt="Nature Scene"
        />
      </div>
      <div className="text-content">
        <h4>About</h4>
        <h2>We’re Passionate About Delivering Growth Services</h2>
        <p>
          Our goal is to provide the right business growth services at the
          appropriate time so companies can benefit from the created momentum
          and thrive for a long period of time.
        </p>
        <ul>
          <li>Everything we recommend has a direct positive impact</li>
          <li>You will become an important partner of our company</li>
        </ul>
        <div className="stats">
          <div className="stat">
            <CountUp end={231} />
            <span>Happy Users</span>
          </div>
          <div className="stat">
            <CountUp end={121} />
            <span>Issues Solved</span>
          </div>
          <div className="stat">
            <CountUp end={159} />
            <span>Good Reviews</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
