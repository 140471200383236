
import SliderSection from './components/SliderSection';
import InfoSection from './components/Sect3';
import ArrowSections from './components/Sect4';

import StatsSection from './components/Slider';


const HomePage = () => {
    return (<>
   
      <SliderSection />
      <ArrowSections />
      <InfoSection/>
      <StatsSection/>

    </>);
};

export default HomePage;