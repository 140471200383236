import React from 'react';
import { useParams, Link } from 'react-router-dom';
import "./styles/BrandDetail.css";

const BrandDetails = () => {
  const { id } = useParams();
  const brands = JSON.parse(localStorage.getItem('brands'));
  const brand = brands.find((b) => b.id === parseInt(id));

  return (
    <div className="brand-details-page">
      {/* Breadcrumb Navigation */}
      <div className="breadcrumb">
        <Link to="/" className="home-link">ƏSAS SƏHİFƏ</Link>
        <span className="separator"> &gt; </span>
        <span className="current-page">BRENDLƏR</span>
      </div>

      {/* Brand Details Section */}
      <div className="brand-details">
        <img className="brand-image" src={brand.image} alt={brand.name} />
        <div className="brand-content">
          <h2 className="brand-title">{brand.name}</h2>
          <p className="brand-description">{brand.description}</p>
          
          {/* Links Section */}
          <div className="brand-links">
            {brand.links?.map((link, index) => (
              <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="brand-link">
                {link.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandDetails;
