import React from 'react';
import './styles/footer.css';
import { Link } from 'react-router-dom';
import logo from './hun motors.svg'; // Import the SVG logo

const Footer = () => {
  return (
    <footer className="footer">
   
      <div className="container-fluid footer-content">
        <div className="footer-section">
        <div className="footer-logo">
            <img src={logo} alt="Hunmotors" width="150" height="auto" /> {/* Adjust the size if needed */}
          </div>
          <h5>Bizimlə qalın</h5>
          <div className="social-icons">
            <a href="https://www.instagram.com/hunmotors.az?igsh=YmE3dGVzaGVvbTR4&utm_source=qr" className="social-icon">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/in/nabi-hac%C4%B1yev-09b404331/" className="social-icon">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>

        
        </div>
        <div className="footer-section">
          <h5>Müştəri xidmətləri</h5>
          <ul>
            <li><Link to="/brands">Brendlər</Link></li>
            <li><Link to="/certficates">Sertifikatlar</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h5>Haqqımızda</h5>
          <ul>
            <li><Link to="/about">Haqqımızda</Link></li>
            <li><Link to="/elaqe">Bizimlə əlaqə</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h5>Bizimlə əlaqə</h5>
          <ul>
            <li><i className="fas fa-envelope"></i> info@hunmotors.com</li>
            <li><i className="fas fa-phone"></i> (+99455) 387 30 30</li>
            <li><i className="fas fa-map-marker-alt"></i> Gəncə-Şəhəri</li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>2024 © Hun-Motors</p>
      </div>
    </footer>
  );
};

export default Footer;
