import React from 'react';
import { Link } from 'react-router-dom';
import "./styles/Brands.css";

const BrandList = () => {
  const brands = JSON.parse(localStorage.getItem('brands'));

  return (
    <div className="brand-gallery-page">
      

      <div className="brand-gallery">
        {brands.map((brand) => (
          <div className="card-brand" key={brand.id}>
            <Link to={`/brand/${brand.id}`}>
              <img src={brand.image} alt={brand.name} className="card-image" />
              <h3 className="card-title">{brand.name}</h3>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandList;
