import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles/Nav.css';
import logo from './hun motors.svg'; // Import the SVG

const Navbar = () => {
  const [scroll, setScroll] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  window.addEventListener('scroll', handleScroll);

  return (
    <nav className={`navbar ${scroll ? 'scrolled' : ''}`}>
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Hunmotors" /> {/* Use the imported SVG here */}
          </Link>
        </div>
        <button className="menu-toggle" onClick={handleMenuToggle}>
          <i className={menuOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
        </button>
        <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <li><Link to="/brands">Brendlər</Link></li>
          <li><Link to="/certificates">Sertifikatlar</Link></li>
          <li><Link to="/haqqimizda">Haqqımızda</Link></li>
          <li><Link to="/elaqe">Əlaqə</Link></li>
          <li><Link to="https://b4b.hunmotors.com/">Giriş</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
