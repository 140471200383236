import React, { useState, useEffect } from 'react';
import Contact from './components/Contacts';
import NavBar from './components/Navbar';
import BrandList from './components/Brands';
import BrandDetails from './components/BrandDetail';
import Footer from "./components/Footer";
import "./App.css"
import AboutPage from './components/About';
import CertificatesPage from './components/CertificatesPage'; // Adjust the path as needed
import HomePage from "./HomePage"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const brands = [
      { id: 1, name: 'BGA', image: 'https://shate-m.by/Data/Image?image=2fd027c30f564992a89351269e01f537.png', description: 'BGA-nın hissələrinin çeşidinə 3300-dən çox məqalə daxildir. İngilis ehtiyat hissələrinin fotoşəkilləri olan fərdi qablaşdırma ilə asanlıqla tanınacaq. Qablaşdırma dizaynına sarı, tünd yaşıl, boz və açıq yaşıl rənglər daxildir. Kiçik istehlak materialları və avtomobil kimyəvi maddələri bəzən qara qablaşdırmaya yerləşdirilir. Nəzərə alın ki, ehtiyat hissəsi olan qutunun içərisində onun quraşdırılması üçün təlimatlar var.' },
      { id: 2, name: 'ELRİNG', image: 'https://s8.hostingkartinok.com/uploads/images/2018/02/f2849a8dd116f131263f861d7ef51020.jpg', description: 'Orijinal Elring məhsulları bütün dünyada dilerlər, mexaniklər və müştərilər arasında tanınır. Bu məhsullara tam və qismən xidmət üçün silindr başlığı və möhürləyici sistemlər, yağ möhürləri və klapan kök möhürləri, möhürləyici birləşmələr və yiv bağlayıcılar, həmçinin silindr başlığı boltlar və tam conta dəstləri daxildir.' },
      { id: 3, name: 'Gates', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8_esXOLdHv0QNgGJGTYvNn_JeYedW_OgBJQ&s', description: 'Gates dünyaca məşhur şirkətdir. O, həm avtomobil hissələri, həm də sənaye və tikinti üçün məhsullar istehsal edir. 18 ölkədə 150 ​​000-dən çox distribyutor bu şirkətlə əməkdaşlıq edir. 40 avtomobil istehsalçısının konveyerlərini ehtiyat hissələri ilə təmin edir. Bu gün Geyts xronometraj hissələri bazarının 52%-ni təşkil edir. Bütün məhsullar bir sıra beynəlxalq standartlara uyğundur: EAQF, QS 9000, ISO 14001, VDA, TS 16949.' },
      { id: 4, name: 'VİCTOR REİNZ', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBlxLvxtcrBkwUEKsj-wErWVr42cSzCsR0zg&s', description: 'Victor Reinz dünyanın demək olar ki, hər bir mühərrik istehsalçısını® əsas tətbiqlər üçün məhsullarla təmin edir. Etibarlı sızdırmazlıq həlləri təqdim etməsinin 110 illiyini qeyd edən Victor Reinz, ekspertlər tərəfindən hazırlanmış eksklüziv conta dəstləri təklif edir.' },
      { id: 5, name: 'VAN MARK', image: 'https://media.licdn.com/dms/image/v2/C510BAQFKCQLUxbOvzw/company-logo_200_200/company-logo_200_200/0/1631357960962?e=2147483647&v=beta&t=hzSK1ZdmQIWpr485RIX9lIyGbTVtappjc_2qd4KhON0', description: 'Van Mark alətləri yaşayış evlərinin xarici görünüşünün yenidən qurulması və tikinti sənayesində, eləcə də metal dam örtüyü və dirək çərçivə tikinti sənayesində geniş istifadə olunur və həmişə İcarə sənayesində №1 alətlər kimi qiymətləndirilib. Biz alətlərimizi istehsal edirik və dünya üzrə səlahiyyətli distribyutorlar vasitəsilə satırıq.' },
    ];

    // Simulate loading delay
    setTimeout(() => {
      localStorage.setItem('brands', JSON.stringify(brands));
      setLoading(false); // Stop loading after setting brands
    }, 1000); // Simulate a 1 second delay
  }, []);

  if (loading) {
    return (
      <div className="neon-loading-screen">
      <div className="neon-loader">
        <div className="neon-circle"></div>
        <p className="neon-text">Yüklənir...</p>
      </div>
    </div>
    );
  }

  return (
    <div>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/brands" element={<BrandList />} />
          <Route path="/certificates" element={<CertificatesPage />} />
          <Route path="/haqqimizda" element={<AboutPage/>} />
          <Route path="/elaqe" element={<Contact />} />
          <Route path="/brand/:id" element={<BrandDetails />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
