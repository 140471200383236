import React from 'react';
import { Link } from 'react-router-dom';
import "./styles/AboutPage.css";

const AboutPage = () => {
  return (
    <div className="about-page">
      {/* Breadcrumb Navigation */}
      <div className="breadcrumb">
        <Link to="/" className="home-link">ƏSAS SƏHİFƏ</Link>
        <span className="separator"> &gt; </span>
        <span className="current-page">HAQQIMIZDA</span>
      </div>

      {/* Main Content */}
      <div className="about-content">
        <h2 className="about-title">Şirkət Profili</h2>
        <p className="about-paragraph">
          2009-cu ildə qurulan HunMotors, avtomobil ehtiyat hissələrinin satışında ixtisaslaşmış bir şirkətdir. Şirkətimiz keyfiyyət, müştəri məmnuniyyəti və peşəkar xidmət prinsipləri üzərində qurulmuşdur. İlk gündən etibarən avtomobil sektorunda etibarlı bir tərəfdaş olaraq tanınmağı hədəfləyən HunMotors, geniş məhsul çeşidi və müştəri yönümlü yanaşması ilə bazarda sürətlə önə çıxmışdır.
        </p>
        <p className="about-paragraph">
          Mercedes Benz, BMW, Audi, Volkswagen, Toyota, Nissan və bir çox digər məşhur avtomobil markaları üçün orijinal və yan sənaye ehtiyat hissələri təqdim edən HunMotors, keyfiyyətdən güzəştə getmədən müştərilərinə ən yaxşı məhsulları təqdim edir. Şirkətimizdə, yüksək keyfiyyətli məhsul və xidmət anlayışını əsas tutaraq, hər bir müştərinin ehtiyacını qarşılamaq üçün daim yeniliklərə açıq bir şəkildə fəaliyyət göstəririk.
        </p>

        <h3 className="sub-title">Məhsullarımız və Markalarımız</h3>
        <p className="about-paragraph">
          HunMotors, müxtəlif marka və modellər üçün ehtiyat hissələri təqdim edir. Bizim əsas təchizatçılarımız arasında Mercedes Benz, BMW, Audi, Toyota, Volkswagen, Opel, Renault, Hyundai və bir çox digər beynəlxalq markalar yer alır. Məhsullarımız arasında sükan, əyləc sistemləri, mühərrik hissələri, süspansiyon sistemləri, elektrik komponentləri və avtomobilin hər nöqtəsində istifadə olunan ehtiyat hissələri mövcuddur.
        </p>

        <h3 className="sub-title">Bizim Missiyamız</h3>
        <p className="about-paragraph">
          HunMotors-un əsas missiyası, müştərilərimizə ən yüksək keyfiyyətdə, güvənli və uzunömürlü avtomobil ehtiyat hissələri təqdim etməkdir. Qısa müddətli mənfəət əvəzinə uzunmüddətli əməkdaşlıq və müştəri məmnuniyyətini əsas tutaraq, hər bir müştərimizə fərdi yanaşma ilə xidmət göstəririk. Hədəfimiz, avtomobil sahiblərinin və təmir mərkəzlərinin ilk seçimi olmaqdır.
        </p>

        <h3 className="sub-title">Vizyonumuz</h3>
        <p className="about-paragraph">
          Şirkətimizin vizyonu, ehtiyat hissələri sektorunda lider mövqeyə yüksəlmək və müştərilərimizə hər zaman keyfiyyətli məhsul və xidmət təklif edən bir marka olmaqdır. Biz, bazarın tələblərinə uyğun olaraq yeniliklər tətbiq etməklə, sektordakı trendləri yaxından izləyərək daim inkişaf etməkdə və xidmət keyfiyyətini artırmaqdadır. Müştəri məmnuniyyətini və güvənini əsas tutaraq, gələcəkdə də bu prinsiplər əsasında fəaliyyətimizi genişləndirməyi planlaşdırırıq.
        </p>

        <h3 className="sub-title">Bizim Dəyərlərimiz</h3>
        <p className="about-paragraph">
          - **Müştəri Məmnuniyyəti:** HunMotors üçün müştəri məmnuniyyəti hər zaman birinci sırada gəlir. Müştərilərimizə ən yaxşı təcrübəni təqdim etmək üçün var gücümüzlə çalışırıq.
          <br />
          - **Keyfiyyət:** Məhsullarımızda və xidmətlərimizdə keyfiyyətdən heç vaxt güzəştə getmirik.
          <br />
          - **Peşəkarlıq:** Hər bir addımımızda peşəkar yanaşma ilə müştərilərimizə dəyər qatmağa çalışırıq.
          <br />
          - **İnnovasiya:** Daim inkişafı dəstəkləyir, yeni texnologiyaları və trendləri izləyərək sektorda fərq yaratmağa çalışırıq.
        </p>

        <h3 className="sub-title">Bizimlə Əlaqə</h3>
        <p className="about-paragraph">
          Əgər məhsullarımız və xidmətlərimiz haqqında daha ətraflı məlumat əldə etmək və ya birbaşa bizimlə əlaqə qurmaq istəyirsinizsə, zəhmət olmasa <Link to="/elaqe" className="contact-link">Əlaqə Səhifəmizi</Link> ziyarət edin. HunMotors olaraq, hər zaman suallarınızı cavablandırmağa və sizə kömək etməyə hazırıq.
        </p>
      </div>
    </div>
  );
};

export default AboutPage;
