import React, { useEffect, useRef } from "react";
import "./styles/Sect4.css";

const MapSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            section.classList.add("animate");
          } else {
            section.classList.remove("animate");
          }
        });
      },
      { threshold: 0.5 } // Trigger animation when 50% of the section is visible
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) observer.unobserve(section);
    };
  }, []);

  return (
    <div ref={sectionRef} className="centered-section">
      <div className="text-content">
        <h2 className="headline">We Offer Some Of The Best Business Growth Services In Town</h2>
        <p>
          Launching a new company or developing the market position of an existing one
          can be quite an overwhelming process at times.
        </p>
        <blockquote>
          "Our mission here at Aira is to get you through those tough moments relying
          on our team's expertise in starting and growing companies."
        </blockquote>
      </div>
      <div className="image-content">
        <img
          src="https://www.autotrainingcentre.com/wp-content/uploads/2015/11/Why-Every-Auto-Mechanic-Dreads-Working-on-These-4-Cars1.jpg"
          alt="Person on swing"
        />
      </div>
    </div>
  );
};

export default MapSection;
