import React, { useState, useEffect } from 'react';
import './styles/SliderSection.css';

const slides = [
  {
    id: 1,
    image: 'https://i.imghippo.com/files/tGIQJ1727012903.png',
    text: 'Sürət, Güc və Texnologiyanın Birləşdiyi Yer.',
    alt: 'Slide 1',
  },
  {
    id: 2,
    image: 'https://i.pinimg.com/originals/d2/0d/94/d20d94857d4021319687086138fe3187.jpg',
    text: 'Ən keyfiyyətli detallar bizdə!',
    alt: 'Slide 2',
  },
  {
    id: 3,
    image: 'https://i.imghippo.com/files/ruGvI1726758833.jpg',
    text: 'Ən Məhşur və Öndə gedən brendlər burada',
    alt: 'Slide 3',
  },
];

const SliderSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(slideInterval);
  }, []);

  return (
    <div className="slider-section">
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`slide ${index === currentSlide ? 'active' : ''}`}
          style={{ backgroundImage: `url(${slide.image})` }}
        >
          <div className="slide-content">
            <h2>{slide.text}</h2>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SliderSection;
